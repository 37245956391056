import React, { useState } from "react";
import * as worksGridStyle from "../styles/WorksGrid.module.css";
import { Link, useStaticQuery, graphql } from "gatsby";
import {
  motion,
  AnimateSharedLayout,
  AnimatePresence,
  LayoutGroup,
} from "framer-motion";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import GifVideo from "./GifVideo";
import useCursorHandlers from "../hook/useCursorHandlers";

const Card = ({ link, zh, en, children, id, stack }) => {
  return (
        <motion.div
          className={worksGridStyle.cardContainer}
          layoutId={id}
          transition={{
            duration: 1.2,
            ease:[0.16, 1, 0.3, 1]
          }}
        >
          <Link className={worksGridStyle.cardLink} to={link}  stripHash>
            <motion.div
              whileHover={{
                scale: 1.2,
                transition: { ease: "easeOut", duration: 0.5 },
              }}
              className={worksGridStyle.cardLinkItems}
            >
              {children}
            </motion.div>
            <div className={worksGridStyle.cardLinkText}>
            <h3 className={worksGridStyle.stack}>{stack}</h3>
              <h3 className={worksGridStyle.zh}>{zh}</h3>
              <h3 className={worksGridStyle.en}>{en}</h3>
            </div>
          </Link>
        </motion.div>
  );
};

export const Grid = () => {
  const [hover, setHover] = useState(true);
  const data = useStaticQuery(graphql`
    query mediaQuery {
      projects: allMdx(sort: { order: ASC, fields: frontmatter___serial }) {
        nodes {
          id
          frontmatter {
            title_zh
            title_en
            year
            slug
            stack
            thumbType
            thumb_low {
              publicURL
            }
            thumb {
              id
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
        }
      }
    }
  `);
  const projects = data.projects.nodes;
  const cursorHandlers = useCursorHandlers();
  return (
    <motion.div>
      <motion.div
        className={worksGridStyle.yearContainer + " " + worksGridStyle.year2022}
      >
        <div className={worksGridStyle.year}>
          2022
          <span className={worksGridStyle.txt}>
            <br />[ year ]
          </span>
          <span className={worksGridStyle.line}></span>
        </div>
        <motion.div className={worksGridStyle.grid} layout>
          {projects
            .filter((project) => project.frontmatter.year === "2022")
            .map((project) => (
              <Card
                layout
                id={project.frontmatter.thumb.id}
                zh={project.frontmatter.title_zh}
                en={project.frontmatter.title_en}
                stack={project.frontmatter.stack}
                link={"/projects/" + project.frontmatter.slug}
              >
                {project.frontmatter.thumbType === "image" ? (
                  <GatsbyImage
                    className={worksGridStyle.cardLinkImage}
                    image={
                      project.frontmatter.thumb.childImageSharp.gatsbyImageData
                    }
                    alt="CARD"
                    width={520}
                    height={520}
                    decoding="async"
                    loading="lazy"
                    placeholder="blur"
                    overflow="hidden"
                    objectFit="cover"
                    quality={100}
                    transformOptions={{
                      fit: "cover",
                      cropFocus: "attention",
                    }}
                    {...cursorHandlers}
                  />
                ) : (
                  <GifVideo
                    src={project.frontmatter.thumb_low.publicURL}
                    className={worksGridStyle.cardLinkVideo}
                    {...cursorHandlers}
                  />
                )}
              </Card>
            ))}
        </motion.div>
      </motion.div>
      <div
        className={worksGridStyle.yearContainer + " " + worksGridStyle.year2021}
      >
        <div className={worksGridStyle.year}>
          2021
          <span className={worksGridStyle.txt}>
            <br />[ year ]
          </span>
          <span className={worksGridStyle.line}></span>
        </div>
        <div className={worksGridStyle.grid}>
          {projects
            .filter((project) => project.frontmatter.year === "2021")
            .map((project) => (
              <Card
                id={project.frontmatter.thumb.id}
                zh={project.frontmatter.title_zh}
                en={project.frontmatter.title_en}
                stack={project.frontmatter.stack}
                link={"/projects/" + project.frontmatter.slug}
              >
                {project.frontmatter.thumbType === "image" ? (
                  <GatsbyImage
                    className={worksGridStyle.cardLinkImage}
                    image={
                      project.frontmatter.thumb.childImageSharp.gatsbyImageData
                    }
                    alt="CARD"
                    width={520}
                    height={520}
                    decoding="async"
                    loading="lazy"
                    placeholder="blur"
                    overflow="hidden"
                    objectFit="cover"
                    quality={100}
                    transformOptions={{ fit: "cover", cropFocus: "attention" }}
                    {...cursorHandlers}
                  />
                ) : (
                  <GifVideo
                    src={project.frontmatter.thumb_low.publicURL}
                    className={worksGridStyle.cardLinkVideo}
                    {...cursorHandlers}
                  />
                )}
              </Card>
            ))}
        </div>
      </div>
      <div
        className={worksGridStyle.yearContainer + " " + worksGridStyle.year2020}
      >
        <div className={worksGridStyle.year}>
          2020
          <span className={worksGridStyle.txt}>
            <br />[ year ]
          </span>
          <span className={worksGridStyle.line}></span>
        </div>
        <div className={worksGridStyle.grid}>
          {projects
            .filter((project) => project.frontmatter.year === "2020")
            .map((project) => (
              <Card
                id={project.frontmatter.thumb.id}
                zh={project.frontmatter.title_zh}
                en={project.frontmatter.title_en}
                stack={project.frontmatter.stack}
                link={"/projects/" + project.frontmatter.slug}
              >
                {project.frontmatter.thumbType === "image" ? (
                  <GatsbyImage
                    className={worksGridStyle.cardLinkImage}
                    image={
                      project.frontmatter.thumb.childImageSharp.gatsbyImageData
                    }
                    alt="CARD"
                    width={520}
                    height={520}
                    decoding="async"
                    loading="lazy"
                    placeholder="blur"
                    overflow="hidden"
                    objectFit="cover"
                    quality={100}
                    transformOptions={{ fit: "cover", cropFocus: "attention" }}
                    {...cursorHandlers}
                  />
                ) : (
                  <GifVideo
                    src={project.frontmatter.thumb_low.publicURL}
                    className={worksGridStyle.cardLinkVideo}
                    {...cursorHandlers}
                  />
                )}
              </Card>
            ))}
        </div>
      </div>
      <div
        className={worksGridStyle.yearContainer + " " + worksGridStyle.year2019}
      >
        <div className={worksGridStyle.year}>
          2019
          <span className={worksGridStyle.txt}>
            <br />[ year ]
          </span>
          <span className={worksGridStyle.line}></span>
        </div>
        <div className={worksGridStyle.grid}>
          {projects
            .filter((project) => project.frontmatter.year === "2019")
            .map((project) => (
              <Card
                id={project.frontmatter.thumb.id}
                zh={project.frontmatter.title_zh}
                en={project.frontmatter.title_en}
                stack={project.frontmatter.stack}
                link={"/projects/" + project.frontmatter.slug}
              >
                {project.frontmatter.thumbType === "image" ? (
                  <GatsbyImage
                    className={worksGridStyle.cardLinkImage}
                    image={
                      project.frontmatter.thumb.childImageSharp.gatsbyImageData
                    }
                    alt="CARD"
                    width={520}
                    height={520}
                    decoding="async"
                    loading="lazy"
                    placeholder="blur"
                    overflow="hidden"
                    objectFit="cover"
                    quality={100}
                    transformOptions={{ fit: "cover", cropFocus: "attention" }}
                    {...cursorHandlers}
                  />
                ) : (
                  <GifVideo
                    src={project.frontmatter.thumb_low.publicURL}
                    className={worksGridStyle.cardLinkVideo}
                    {...cursorHandlers}
                  />
                )}
              </Card>
            ))}
        </div>
      </div>
    </motion.div>
  );
};
