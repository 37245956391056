// extracted by mini-css-extract-plugin
export var maindiv = "Layout-module--maindiv--XxdN0";
export var layoutContainer = "Layout-module--layoutContainer--QhFVp";
export var layoutContainer1 = "Layout-module--layoutContainer-1--Kw2Uz";
export var navLinks = "Layout-module--nav-links--U4L6G";
export var navLinkItem = "Layout-module--nav-link-item--3LcQN";
export var navLinkText = "Layout-module--nav-link-text--iMcQD";
export var logoCircle = "Layout-module--logo-circle--ZzNZc";
export var logoManatee = "Layout-module--logo-manatee--2EN+k";
export var txt1 = "Layout-module--txt-1--2n+GJ";
export var txt2 = "Layout-module--txt-2--AXmWN";
export var txt3 = "Layout-module--txt-3--LcxyD";
export var motiondiv = "Layout-module--motiondiv--4aY5R";
export var footer = "Layout-module--footer--3xhw4";