import React, { useEffect, useRef, useState } from "react";

import * as LayoutStyle from "../styles/Layout.module.css";
import { motion } from "framer-motion";
import { Nav } from "./Nav";
import { Landing } from "./Landing";
// import {LoadingOpen} from './Header'

export const Footer = () => {
  return <div className={LayoutStyle.footer}>© [2022] Manatee. ʕ◉ᴥ◉ʔ</div>;
};
export const BannerRowLeft = ({ txt }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 1,
      }}
      className={LayoutStyle.txt1}
    >
      {txt}
      <span> </span>
    </motion.div>
  );
};

export const BannerRowRight = ({ txt1, txt2 }) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.1,
          duration: 1,
        }}
        className={LayoutStyle.txt2}
      >
        {txt1}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          staggerChildren: 0.5,
          duration: 1,
        }}
        className={LayoutStyle.txt3}
      >
        {txt2}
      </motion.div>
    </>
  );
};

export const Layout = ({ children }) => {
  const txt1Ref = useRef();
  return (
    <motion.div className={LayoutStyle.layoutContainer1} layout >
      <Nav />
      {children}
      <Footer />
    </motion.div>
  );
};
