import React, { useEffect, useRef, useState } from "react";
import useCursorHandlers from "../hook/useCursorHandlers";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import * as NavStyle from "../styles/Nav.module.css";

export const Nav = () => {
  const navRef = useRef();
  const cursorHandlers = useCursorHandlers();
  const [LoadingOpen, setLoadingOpen] = useState(true);
  return (
    <motion.div className={NavStyle.layoutContainer}>
      <motion.nav
        initial={{ opacity: 0, y: 50 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 1,
            ease: [0.37, 0, 0.63, 1],
          },
        }}
        ref={navRef}
      >
        <StaticImage
          className={NavStyle.logoManatee}
          alt="MANATEE"
          src="../images/manatee-white.png"
          placeholder="none"
        />
        <ul className={NavStyle.navLinks}>
          <motion.li
            whileHover={{
              scale: 1.2,
              transition: { ease: "easeOut", duration: 0.1 },
            }}
            className={NavStyle.navLinkItem}
            {...cursorHandlers}
          >
            <Link
              className={NavStyle.navLinkText}
              to="/"
              // onMouseDown={setLoadingOpen(false)}
              state={{ LoadingOpen }}
              scroll={false}
            >
              Home
            </Link>
          </motion.li>
          <motion.li
            whileHover={{
              scale: 1.2,
              transition: { ease: "easeOut", duration: 0.1 },
            }}
            className={NavStyle.navLinkItem}
            {...cursorHandlers}
          >
            <Link
              swipe
              direction="up"
              className={NavStyle.navLinkText}
              to="/projects"
              scroll={false}
            >
              Projects
            </Link>
          </motion.li>
          <motion.li
            whileHover={{
              scale: 1.2,
              transition: { ease: "easeOut", duration: 0.1 },
            }}
            className={NavStyle.navLinkItem}
            {...cursorHandlers}
          >
            <Link className={NavStyle.navLinkText} to="/about" scroll={false}>
              About
            </Link>
          </motion.li>
        </ul>
        <StaticImage
          className={NavStyle.logoCircle}
          alt="LOGO"
          src="../images/Basic-logo-white 1@3x.png"
          placeholder="none"
        />
      </motion.nav>
    </motion.div>
  );
};

