// extracted by mini-css-extract-plugin
export var grid = "WorksGrid-module--grid--WM5gj";
export var yearContainer = "WorksGrid-module--yearContainer--7Le36";
export var year2020 = "WorksGrid-module--year2020--jwqM7";
export var cardContainer = "WorksGrid-module--cardContainer--QS+Mb";
export var cardLink = "WorksGrid-module--cardLink--v4Vlv";
export var cardLinkItems = "WorksGrid-module--cardLinkItems--6cqBL";
export var cardLinkImage = "WorksGrid-module--cardLinkImage--bw7Rg";
export var gatsbyImageWrapper = "WorksGrid-module--gatsby-image-wrapper--O1s6H";
export var cardLinkText = "WorksGrid-module--cardLinkText--SbXvP";
export var zh = "WorksGrid-module--zh--fIKrJ";
export var en = "WorksGrid-module--en--zca0R";
export var stack = "WorksGrid-module--stack--2GrQo";
export var year = "WorksGrid-module--year--5Cj9Y";
export var txt = "WorksGrid-module--txt--4CBfv";
export var line = "WorksGrid-module--line--hh136";
export var cardLinkVideo = "WorksGrid-module--cardLinkVideo--JPDk8";
export var year2022 = "WorksGrid-module--year2022--AVLVf";