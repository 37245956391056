// extracted by mini-css-extract-plugin
export var layoutContainer = "Nav-module--layoutContainer--jG37v";
export var navLinks = "Nav-module--nav-links--A-1-g";
export var navLinkItem = "Nav-module--nav-link-item--NOG-2";
export var navLinkText = "Nav-module--nav-link-text--q2Ulf";
export var logoCircle = "Nav-module--logo-circle--O37P4";
export var logoManatee = "Nav-module--logo-manatee--x0MZJ";
export var txt1 = "Nav-module--txt1--ffT-3";
export var intro = "Nav-module--intro--8F6HA";
export var intro1 = "Nav-module--intro-1--nrTtW";
export var intro2 = "Nav-module--intro-2--KkfF8";