import React, { useState, useRef } from "react";
import { Layout } from "../../components/Layout";
import CursorContextProvider from "../../components/CursorContextProvider";
import { Cursor } from "../../components/Cursor";
import { motion, AnimatePresence } from "framer-motion";
import "../../styles/style.css";
import { useInView } from "react-intersection-observer";
import { Grid } from "../../components/WorksGrid";


const Works = () => {
  return (
    <CursorContextProvider>
    <title>Manatee - Portfolio</title>
      <Cursor />
      <Layout>
        <motion.div
          initial={{ opacity: 0, y: 80 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: [0.37, 0, 0.63, 1] },
          }}
          layout
          layoutScroll
        >
          <Grid />
        </motion.div>
      </Layout>
    </CursorContextProvider>
  );
};

export default Works;
